import {inject, Injectable, makeStateKey, StateKey, TransferState} from '@angular/core';
import {TranslocoLoader} from '@ngneat/transloco';
import {Observable, of} from 'rxjs';

import {TransferHttpService} from '../../transfer-http/transfer-http.service';
import {getTransferStateTranslationsKey, TRANSLATION_FOLDER_PATH} from '../config/transloco.config';

@Injectable({providedIn: 'root'})
export class TranslocoBrowserLoaderService implements TranslocoLoader {

  private readonly http = inject(TransferHttpService);
  private readonly transferState = inject(TransferState);

  constructor() {
  }

  getTranslation(lang: string): Observable<any> {
    const metaKey = getTransferStateTranslationsKey(lang);
    const key: StateKey<number> = makeStateKey<number>(metaKey);
    const cacheData: any = this.transferState.get(key, null);

    if (cacheData) {
      return of(cacheData);
    }

    // return this.http.get(`/${TRANSLATION_FOLDER_PATH}${lang}.json`);
    return new Observable((observer) => {
      if (cacheData) {
        observer.next(cacheData);
        observer.complete();
        return;
      }
      // return this.http.get(`/src/${TRANSLATION_FOLDER_PATH}${lang}.json`);

      import(`./../../../../../${TRANSLATION_FOLDER_PATH}${lang}.json`).catch(e => {
        console.error(e);
      }).then(res => {
        observer.next(res.default);
        observer.complete();
      });
      // import(`../../../../../${TRANSLATION_FOLDER_PATH}${lang}.json`).then(res => {
      //   observer.next(res.default);
      //   observer.complete();
      // });
    });
  }

}
