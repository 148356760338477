import {isPlatformBrowser} from '@angular/common';
import {inject, Injectable, PLATFORM_ID} from '@angular/core';
import {CookieService} from '@gorniv/ngx-universal';

@Injectable()
export class UniversalStorageService {

  [index: number]: string;

  [key: string]: any;

  length: number;
  cookies: any;

  private readonly platform = inject(PLATFORM_ID);

  constructor(private cookieService: CookieService) {
  }

  clear(): void {
    this.cookieService.removeAll();
  }

  getAll(): object {
    return this.cookieService.getAll();
  }

  getItem(key: string): string {
    return this.cookieService.get(key);
  }

  key(index: number): string {
    return this.cookieService.getAll().propertyIsEnumerable[index];
  }

  removeItem(key: string): void {
    this.cookieService.remove(key);
  }

  setItem(key: string, data: string | null): void {
    if (!isPlatformBrowser(this.platform)) {
      return;
    }
    // @ts-ignore
    this.cookieService.put(key, data);
  }
}
