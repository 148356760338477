import {Params} from '@angular/router';

import {UserRoleType} from '../directories';
import {ConditionalStatus} from '../general';
import {PaginationDto} from '../response';
import {SuppliersSearchContactsDto, SuppliersSearchGeneralDto, SuppliersSearchUserDto} from '../suppliers-search';

export type RegistrationProvider = 'email' | 'facebook' | 'google';

export class RegistrationDto {
  constructor(
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly role: UserRoleType,
    public readonly groupId: number,
    public readonly positionId: number,
    public readonly phoneCountryCode: string | null,
    public readonly phoneNumber: string | null,
    public readonly email: string,
    public readonly password: string,
    public readonly checkEmailSubscription: ConditionalStatus,
    public readonly companyName: string | null = null,
    public readonly provider: string | null = null,
    public readonly hash: string | null = null,
    public readonly referral: string | null = null,
    public readonly coupleId?: string | null,
  ) {
  }
}

export class RegistrationProviderDto {
  constructor(
    private readonly name: RegistrationProvider,
    private readonly userId: string | null = null,
    private readonly accessToken: string | null = null,
  ) {
  }

  formatProvider(): string | null {
    const userId = this.userId;
    const name = this.name;
    const accessToken = this.accessToken;
    if (name === 'email' || !userId || !accessToken) {
      return null;
    }
    return JSON.stringify({name, userId, accessToken});
  }
}

export class RegistrationTokenDto {
  constructor(
    public readonly hash: string | null,
    public readonly coupleId?: string | null,
    public readonly referralToken?: string | null,
    public readonly role?: UserRoleType | null,
  ) {
  }
}

export class RegistrationEmailCheckPayload extends RegistrationTokenDto {
  constructor(
    public readonly email: string,
    hash,
    coupleId?,
  ) {
    super(hash, coupleId);
  }
}

export interface RegistrationEmailCheckDto {
  readonly exist: boolean;
}

export type ClaimSupplierParams = {
  [key in keyof ClaimSupplierParamsDto]?: ClaimSupplierParamsDto[key];
};

export class ClaimSupplierParamsDto {
  constructor(
    public readonly page = 1,
    public readonly search: string | null = '',
  ) {
  }
}

export class ClaimSupplierListParams {
  constructor(
    public token: string,
    public search: string | null,
    public page: number | null,
    public locationLat: number | null,
    public locationLng: number | null,
  ) {
  }
}

export interface SupplierClaimItemDto {
  generalDetails: Omit<SuppliersSearchGeneralDto, 'statusSearch' | 'branchName' | 'contactPerson'>;
  supplier: Pick<SuppliersSearchUserDto, 'id'>;
  contacts: SuppliersSearchContactsDto;
}

export interface SupplierClaimListDto {
  readonly models: SupplierClaimItemDto[];
  readonly pagination: PaginationDto;
}

export interface SuppliersClaimSelectionItem extends SupplierClaimItemDto {
  selected: boolean;
  isMainOffice: boolean;
}

export class ClaimSupplierPayload {
  constructor(
    public token: string,
    public mainOffice: number | null,
    public branches: number[],
  ) {
  }
}

export interface RegistrationParams extends Params {
  role?: UserRoleType | null;
}
