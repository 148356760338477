import {CompanyDto} from './company-auth';

import {InvitationToBoatDto} from '../invitation';
import {JobSearchUserWorkPreferenceDto} from '../job-search';
import {LocationDto} from '../location';
import {SupplierSearchStatus} from '../supplier';
import {SupplierProfileEditNavLinkName} from '../supplier/supplier-profile-navigation.model';
import {User, UserFleetData} from '../user';

export interface TokenDto {
  readonly token: string;
}

export type SupplierRedirectType = SupplierProfileEditNavLinkName | false;

export class AuthDto {
  constructor(
    public readonly token: string,
    public readonly user: User,
    public readonly permissions: string[],
    public readonly invitationsToBoat: InvitationToBoatDto | null,
    public readonly boatSearch: boolean,
    public readonly isConnectedToBoats: boolean,
    public readonly oneBoatId: number | null,
    public readonly needRedirectToCv: boolean,
    public readonly cv: AuthCvDto,
    public readonly company: CompanyDto,
    public readonly releaseInformed: boolean,
    public readonly supplierRedirect: SupplierRedirectType,
    public readonly location: LocationDto | null,
    public readonly supplierStatus: SupplierSearchStatus | null,
    public readonly referralLinkToken: string | null,
    public readonly fleet: UserFleetData | null,
  ) {
  }
}

export type ClaimAuthDto = {
  readonly token: string;
  readonly isClaimSupplier: boolean;
}

export type RegistrationAuthDto = AuthDto | AuthDto & { isClaimSupplier: boolean} | ClaimAuthDto | [];

export const isAuthData = (data: AuthDto | unknown): data is AuthDto => (data as AuthDto).token !== undefined && (data as AuthDto).user !== undefined;

export type AuthBehavior = 'after_change_avatar';

export type AuthCvDto = {
  readonly workPreference: JobSearchUserWorkPreferenceDto
}

export class LogOutConfigDto {
  constructor(
    public readonly message: string,
    public readonly timeout = 3000,
    public readonly translate = false,
  ) {
  }

}
