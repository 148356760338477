import {TRANSLOCO_CONFIG, TranslocoConfig, translocoConfig} from '@ngneat/transloco';

import {environment} from '@environments/environment';

export const DEFAULT_TRANSLOCO_CONFIG: TranslocoConfig = {
  failedRetries: 2,
  flatten: {aot: false},
  interpolation: ['{{', '}}'],
  missingHandler: {
    allowEmpty: false,
    logMissingKey: true,
    useFallbackTranslation: false,
  },
  availableLangs: ['en'],
  defaultLang: 'en',
  fallbackLang: 'en',
  reRenderOnLangChange: true,
  prodMode: environment.production,
};

export const TRANSLOCO_CONFIG_PROVIDER = {
  provide: TRANSLOCO_CONFIG,
  useValue: translocoConfig(DEFAULT_TRANSLOCO_CONFIG),
};

export const TRANSLATION_META_STATE_KEY_BASE = 'transfer-translate-';
export const TRANSLATION_FOLDER_PATH = 'assets/i18n/';

export function getTransferStateTranslationsKey(lang: string) {
  return `${TRANSLATION_META_STATE_KEY_BASE}${lang}`;
}
