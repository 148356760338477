/**
 * Unit system alias synchronized with API
 */
export enum UnitSystem {
  IMPERIAL = 1,
  METRIC = 2,
}

/**
 * Key which divide translations in  assets/i18n/unit-system/en.json file
 */
export type UnitSystemTranslateKey = 'imperial' | 'metrical';
/**
 * Key inside UnitSystemTranslateKey
 */
export type UnisSystemTranslateItems = 'length' | 'lengthShort' | 'capacity' | 'distance' | 'distanceShort';

/**
 * Type of conversion system:
 * <br><b>length</b> - meters to foot
 * <br><b>capacity</b> - liters to gallon
 * <br><b>distance</b> - kilometers to miles
 */
export type ConversionSystem = 'length' | 'capacity' | 'distance';

export class UnitSystemItem {
  constructor(
    public readonly value: UnitSystem,
    public readonly title: string,
    public readonly list: string,
  ) {
  }
}
