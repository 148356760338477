import {PhoneNumber} from '@controls/phone-number';

import {CompanyTypeDto, CountryDto} from '../directories';
import {ConditionalStatus} from '../general';
import {UploadedFile} from '../uploaded-file';

export enum CompanyReadyStatus {
  Draft = 1,
  Pending = 2,
  Active = 3,
  Deleted = 4,
  Blocked = 5,
}
export enum CompanyType {
  CrewAgency = 1,
  YachtManagement = 2,
  ProjectManagement = 3,
  SalesCharters = 4
}

export interface CompanyProfileForm {
  logo: UploadedFile | null;
  website: string;
  companyType: CompanyType;
  email: string;
  phone: PhoneNumber;
  removeLogo: ConditionalStatus | null;
  companyName?: string;
}

export class AddCompanyProfilePayload {
  readonly name: string;
  readonly logo: string | null;
  readonly website: string | null;
  readonly typeId: number;
  readonly email: string | null;
  readonly phoneCountryCode: string | null = null;
  readonly phoneNumber: string | null = null;

  constructor(form: CompanyProfileForm) {
    const {phone, companyName, logo} = form;
    this.logo = logo instanceof UploadedFile && logo.fileName ? logo.fileName : null;
    this.website = form.website;
    this.typeId = form.companyType;
    this.email = form.email;
    if (phone) {
      this.phoneCountryCode = phone.phoneCountryCode;
      this.phoneNumber = phone.phoneNumber;
    }
    if (companyName) {
      this.name = companyName;
    }
  }
}

export interface AddCompanyProfileDto {
  companyId: number;
}

export class EditCompanyProfilePayload extends AddCompanyProfilePayload {
  readonly removeLogo: ConditionalStatus | null = null;

  constructor(
    public readonly companyId: number,
    form: CompanyProfileForm,
  ) {
    super(form);
    this.removeLogo = !form.logo ? ConditionalStatus.YES : null;
  }
}

export type CompanyProfileDirectories = {
  readonly companyTypes: CompanyTypeDto[];
  readonly countries: CountryDto[];
}

export type CompanyProfileMode = 'add' | 'edit';

export interface CompanyUserProfileForm {
  firstName: string;
  lastName: string;
  phoneNumber: PhoneNumber;
  email: string;
  password: string;
  passwordRepeat: string;
  termsAccept: boolean;
}

export class CompanyUserProfilePayload {
  readonly firstName: string;
  readonly lastName: string;
  readonly phoneNumber: string;
  readonly phoneCountryCode: string;
  readonly email: string;
  readonly password: string;
  readonly companyId: number;

  constructor(
    form: CompanyUserProfileForm,
    companyId: number,
  ) {
    this.firstName = form.firstName;
    this.lastName = form.lastName;
    const {phoneNumber} = form;
    this.phoneNumber = phoneNumber.phoneNumber;
    this.phoneCountryCode = phoneNumber.phoneCountryCode;
    this.email = form.email;
    this.password = form.password;
    this.companyId = companyId;
  }
}

export type CompanyUserProfileDirectories = {
  readonly countries: CountryDto[];
}

export type CompanyLoginDto = Readonly<{
  email: string;
  password: string;
  companyId: number;
}>;

export type CompanyDto = Readonly<{
  email: string;
  id: number;
  logo: string | null;
  name: string;
  phone: string;
  status: CompanyReadyStatus;
  type: CompanyType;
}>;

export interface CompanyFormI {
  companyId: number;
  website: string;
  logo: string | null;
  phoneCountryCode: string;
  phoneNumber: string;
  email: string;
  typeId: CompanyType;
  status: CompanyReadyStatus;
  userId: number;
  dateCreate: string;
  dateUpdate: string;
}

export class CompanyFormDto {
  readonly companyId: number;
  readonly website: string;
  readonly logo: string | null;
  readonly phone: PhoneNumber;
  readonly email: string;
  readonly companyType: CompanyType;
  readonly status: CompanyReadyStatus;
  readonly userId: number;
  readonly dateCreate: string;
  readonly dateUpdate: string;
  constructor(
    data: CompanyFormI,
  ) {
    this.companyId = data.companyId;
    this.website = data.website;
    this.logo = data.logo;
    this.phone = {phoneCountryCode: data.phoneCountryCode, phoneNumber: data.phoneNumber};
    this.email = data.email;
    this.companyType = data.typeId;
    this.status = data.status;
    this.userId = data.userId;
    this.dateCreate = data.dateCreate;
    this.dateUpdate = data.dateUpdate;
  }
}

export enum UserCreateCompanyStatus {
  CanCreate = 1,
  InvalidRole,
  AlreadyCreated
}
