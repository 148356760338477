import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';

import {LocalStorageService} from './local-storage.service';

export function localStorageServiceFactory(): LocalStorageService {
  return new LocalStorageService();
}

@NgModule()
export class LocalStorageModule {

  constructor(@Optional() @SkipSelf() parentModule: LocalStorageModule) {
    if (parentModule) {
      throw new Error('LocalStorageModule module should be imported only in CoreModule.');
    }
  }

  static forRoot(): ModuleWithProviders<LocalStorageModule> {
    return {
      ngModule: LocalStorageModule,
      providers: [
        {
          provide: LocalStorageService,
          useFactory: localStorageServiceFactory,
        },
      ],
    };
  }
}
