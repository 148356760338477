import {ModuleWithProviders, NgModule, Optional, SkipSelf, TransferState} from '@angular/core';


import {AppStateService} from './app-state.service';

import {PlatformBrowserService} from '../browser';
import {LocalStorageService} from '../local-storage';
import {UniversalStorageService} from '../universal-storage';

export function appStateServiceFactory(
  localStorageService: LocalStorageService,
  platformBrowserService: PlatformBrowserService,
  universalStorageService: UniversalStorageService,
  transferState: TransferState,
): AppStateService {
  return new AppStateService(localStorageService, platformBrowserService, universalStorageService, transferState);
}

@NgModule()
export class AppStateModule {

  constructor(@Optional() @SkipSelf() parentModule: AppStateModule) {
    if (parentModule) {
      throw new Error('AppStateModule module should be imported only in CoreModule.');
    }
  }

  static forRoot(): ModuleWithProviders<AppStateModule> {
    return {
      ngModule: AppStateModule,
      providers: [
        {
          provide: AppStateService,
          useFactory: appStateServiceFactory,
          deps: [LocalStorageService, PlatformBrowserService, UniversalStorageService, TransferState],
        },
      ],
    };
  }
}
