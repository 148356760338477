export const environment = {
  production: true,
  apiEndpoint: 'https://api.seazone-dev.idap.pro',
  baseUrl: 'https://seazone-dev.idap.pro',
  apiVersion: 'v1',
  hj: '2523932',
  fbAppKey: 466993514988388,
  fbConfigId: 816940103502704,
  gAppKey: '103145069476-5kstufces1u7k50bdvmauvgj1pdnu7c0.apps.googleusercontent.com',
  gMapKey: 'AIzaSyAu6l7JjNaUhyO8uJgD4GAvFENpGZM4CAM',
  cryptoAESPass: 'ub548V3nKtXH5',
  googleMapId: 'd32dd6ce668f45eb',
};