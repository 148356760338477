import {AuthCvDto, CompanyDto} from '@models/auth';
import {UserFleetPermissionDto} from '@models/boats-fleet/boats-fleet-permission';
import {PreferenceListStateDto} from '@models/charters';
import {LastAddedItem} from '@models/expense';
import {ConditionalStatus} from '@models/general';
import {InvitationToBoatDto} from '@models/invitation';
import {LocationDto} from '@models/location';
import {OutstandingLastAddedItem} from '@models/outstanding-payments';
import {PermissionsDto} from '@models/permissions';
import {User, UserFleetData} from '@models/user';

export class AppState {
  constructor(
    public language: string = 'en',
    public user: User | null = null,
    public token: string | null = null,
    public permissions: PermissionsDto[] | null = null,
    public invitationsToBoat: InvitationToBoatDto | null = null,
    public isConnectedToBoats: ConditionalStatus = ConditionalStatus.NO,
    public boatSearch: ConditionalStatus = ConditionalStatus.NO,
    public lastExpense: LastAddedItem[] | null = null,
    public lastOutstanding: OutstandingLastAddedItem[] | null = null,
    public lastPendingUser: LastAddedItem | null = null,
    public needChangePassword: ConditionalStatus = ConditionalStatus.NO,
    public cv: AuthCvDto | null = null,
    public userCompany: CompanyDto | null = null,
    public lastApaExpense: LastAddedItem[] | null = null,
    public releaseInformed: ConditionalStatus = ConditionalStatus.NO,
    public userDownloadsDateUpdate: number | null = null,
    public userFleetsDateUpdate: number | null = null,
    public location: LocationDto | null = null,
    public referralLinkToken: string | null = null,
    public fleetPermissions: UserFleetPermissionDto[] | null = null,
    public fleet: UserFleetData | null = null,
    public preferenceList: PreferenceListStateDto | null = null,
  ) {
  }
}
