import {NgModule} from '@angular/core';
import {TransferHttpModule} from '@gorniv/ngx-universal';
import {provideTransloco, TranslocoModule} from '@ngneat/transloco';

import {TranslocoBrowserLoaderService} from './transloco-browser-loader.service';

import {DEFAULT_TRANSLOCO_CONFIG} from '../config/transloco.config';

@NgModule({
  imports: [
    TransferHttpModule,
  ],
  providers: [
    provideTransloco({
      config: DEFAULT_TRANSLOCO_CONFIG,
      loader: TranslocoBrowserLoaderService,
    }),
  ],
  exports: [TranslocoModule],
})
export class TranslocoBrowserModule {
}
