import {Inject, Injectable, makeStateKey, TransferState} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay, take} from 'rxjs/operators';

import {isCompanyCrewAgency} from '@helpers/is-company-crew-agency';
import {AuthCvDto, AuthDto, CompanyDto} from '@models/auth';
import {UserFleetPermissionDto} from '@models/boats-fleet/boats-fleet-permission';
import {PreferenceListStateDto} from '@models/charters';
import {UserRoleType} from '@models/directories';
import {LastAddedItem} from '@models/expense';
import {booleanToConditionalStatus, ConditionalStatus, conditionalStatusToBoolean} from '@models/general';
import {InvitationToBoatDto} from '@models/invitation';
import {LocationDto} from '@models/location';
import {OutstandingLastAddedItem} from '@models/outstanding-payments';
import {PermissionsDto} from '@models/permissions';
import {User, UserFleetData} from '@models/user';
import {UnitSystem} from '@unitSystem/models';

import {AppState} from './app-state.model';

import {PlatformBrowserService} from '../browser';
import {LocalStorageService} from '../local-storage';
import {UniversalStorageService} from '../universal-storage';

@Injectable()
export class AppStateService {

  state$: Observable<AppState>;
  userLoaded$: Observable<boolean>;

  private readonly localStorageKey = 'state';
  private readonly unAuthorizedStateKey = makeStateKey<boolean>('UnAuthorized');
  private readonly forcedLogoutStateKey = makeStateKey<boolean | null>('ForcedLogout');

  private state: BehaviorSubject<AppState>;
  private userInfoLoaded$: BehaviorSubject<boolean>;

  get isBrowser(): boolean {
    return this.browserService.isBrowser;
  }

  private get universalStorageToken(): string | null {
    return this.universalStorageService.getItem('_token');
  }

  private set universalStorageToken(token: string | null) {
    token ?
      this.universalStorageService.setItem('_token', token) :
      this.universalStorageService.removeItem('_token');
  }

  get token(): string | null {
    if (this.isBrowser) {
      return this.getStateProperty('token') as string;
    }
    return this.universalStorageToken;
  }

  set token(token: string | null) {
    this.universalStorageToken = token;
    this.setStateProperty('token', token);
  }

  set preferenceListInfo(value: PreferenceListStateDto | null) {
    this.setStateProperty('preferenceList', value);
    value ?
      this.universalStorageService.setItem('_preferenceList', JSON.stringify(value)) :
      this.universalStorageService.removeItem('_preferenceList');
  }

  get preferenceListInfo(): PreferenceListStateDto | null {
    if (this.isBrowser) {
      return this.getStateProperty('preferenceList') as PreferenceListStateDto;
    }
    const value = this.universalStorageService.getItem('_preferenceList');
    return value ? JSON.parse(value) : null;
  }

  get token$(): Observable<string | null> {
    return this.state$.pipe(
      map(state => state.token),
    );
  }

  get isAuthenticated(): boolean {
    const unAuthorized = this.transferState.get<boolean>(this.unAuthorizedStateKey, false);
    if (unAuthorized) {
      this.transferState.remove(this.unAuthorizedStateKey);
      return false;
    }
    return !!this.token;
  }

  get user$(): Observable<User | null> {
    return this.state$.pipe(
      map(state => state.user),
    );
  }

  get user(): User {
    return this.getStateProperty('user') as User;
  }

  get userCv$(): Observable<AuthCvDto | null> {
    return this.state$.pipe(
      map(state => state.cv),
    );
  }

  get userLocation$(): Observable<LocationDto | null> {
    return this.state$.pipe(
      map(state => state.location),
    );
  }

  get unitSystem(): UnitSystem {
    const user = this.user;
    return user?.units || UnitSystem.METRIC;
  }

  set unitSystem(unitSystem: UnitSystem) {
    const user = this.user;
    if (!user) {
      return;
    }
    user.units = unitSystem;
    this.setStateProperty('user', user);
  }

  get isPasswordSet$(): Observable<boolean> {
    return this.user$.pipe(
      map(user => !!user?.passwordSet),
      shareReplay(1),
    );
  }

  private get universalStorageUserRole(): UserRoleType | null {
    return this.universalStorageService.getItem('_userRole') as UserRoleType;
  }

  private set universalStorageUserRole(role: UserRoleType | null) {
    this.universalStorageService.setItem('_userRole', role);
  }

  get userRole(): UserRoleType | null {
    if (this.isBrowser) {
      const user = this.getStateProperty('user');
      return user ? (user as User).roleType : this.universalStorageUserRole;
    }
    return this.universalStorageUserRole;
  }

  get userRole$(): Observable<UserRoleType | null> {
    return this.state$.pipe(
      map(state => {
        const user = state.user;
        return user ? (user as User).roleType : this.universalStorageUserRole;
      }),
    );
  }

  get referralLinkToken$(): Observable<string | null> {
    return this.state$.pipe(
      map(state => state?.referralLinkToken),
    );
  }

  get language(): string {
    return this.getStateProperty('language') as string;
  }

  set permissions(permissions: PermissionsDto[]) {
    this.setStateProperty('permissions', permissions);
  }

  get permissions(): PermissionsDto[] {
    return this.getStateProperty('permissions') as PermissionsDto[];
  }

  get permissions$(): Observable<PermissionsDto[]> {
    return this.state$.pipe(
      distinctUntilChanged(),
      map(state => state.permissions || []),
      shareReplay(1),
    );
  }

  set fleetPermissions(permissions: UserFleetPermissionDto[]) {
    this.setStateProperty('fleetPermissions', permissions);
  }

  get fleetPermissions(): UserFleetPermissionDto[] {
    return this.getStateProperty('fleetPermissions') as UserFleetPermissionDto[];
  }

  private set boatSearch(boatSearch: boolean) {
    const value = booleanToConditionalStatus(boatSearch);
    this.setStateProperty('boatSearch', value);
    this.universalStorageService.setItem('_boatSearch', `${value}`);
  }

  private get boatSearch(): boolean {
    if (this.isBrowser) {
      const value = this.getStateProperty('boatSearch') as ConditionalStatus;
      return conditionalStatusToBoolean(value);
    }
    const item = this.universalStorageService.getItem('_boatSearch');
    return item ? conditionalStatusToBoolean(+item) : false;
  }

  get boatSearch$(): Observable<boolean> {
    if (!this.isBrowser) {
      return of(this.boatSearch);
    }
    return this.state$.pipe(
      map(state => conditionalStatusToBoolean(state.boatSearch)),
    );
  }

  set releaseInformed(releaseInformed: boolean) {
    const value = booleanToConditionalStatus(releaseInformed);
    this.setStateProperty('releaseInformed', value);
    this.universalStorageService.setItem('_releaseInformed', `${value}`);
  }

  get releaseInformed(): boolean {
    if (this.isBrowser) {
      const value = this.getStateProperty('releaseInformed') as ConditionalStatus;
      return conditionalStatusToBoolean(value);
    }
    const item = this.universalStorageService.getItem('_releaseInformed');
    return item ? conditionalStatusToBoolean(+item) : false;
  }

  get releaseInformed$(): Observable<boolean> {
    if (!this.isBrowser) {
      return of(this.releaseInformed);
    }
    return this.state$.pipe(
      map(state => conditionalStatusToBoolean(state.releaseInformed)),
    );
  }

  set isConnectedToBoats(isConnectedToBoats: boolean) {
    const value = booleanToConditionalStatus(isConnectedToBoats);
    this.setStateProperty('isConnectedToBoats', value);
    this.universalStorageService.setItem('_isConnectedToBoats', `${value}`);
  }

  get isConnectedToBoats(): boolean {
    if (this.isBrowser) {
      const value = this.getStateProperty('isConnectedToBoats') as ConditionalStatus;
      return conditionalStatusToBoolean(value);
    }
    const item = this.universalStorageService.getItem('_isConnectedToBoats');
    return item ? conditionalStatusToBoolean(+item) : false;
  }

  get isConnectedToBoats$(): Observable<boolean> {
    if (!this.isBrowser) {
      return of(this.isConnectedToBoats);
    }
    return this.state$.pipe(
      map(state => conditionalStatusToBoolean(state.isConnectedToBoats)),
    );
  }

  set needChangePassword(needChange: boolean) {
    const value = booleanToConditionalStatus(needChange);
    this.setStateProperty('needChangePassword', value);
    this.universalStorageService.setItem('_needChange', `${value}`);
  }

  get needChangePassword(): boolean {
    if (this.isBrowser) {
      const value = this.getStateProperty('needChangePassword') as ConditionalStatus;
      return conditionalStatusToBoolean(value);
    }
    const item = this.universalStorageService.getItem('_needChange');
    return item ? conditionalStatusToBoolean(+item) : false;
  }

  get needChangePassword$(): Observable<boolean> {
    if (!this.isBrowser) {
      return of(this.needChangePassword);
    }
    return this.state$.pipe(
      map(state => conditionalStatusToBoolean(state.needChangePassword)),
    );
  }

  set lastExpense(lastExpense: LastAddedItem[]) {
    this.setStateProperty('lastExpense', lastExpense);
    this.universalStorageService.setItem('_lastExpense', JSON.stringify(lastExpense));
  }

  get lastExpense(): LastAddedItem[] {
    return this.getStateProperty('lastExpense') as LastAddedItem[];
  }

  set lastOutstanding(lastOutstanding: OutstandingLastAddedItem[]) {
    this.setStateProperty('lastOutstanding', lastOutstanding);
    this.universalStorageService.setItem('_lastOutstanding', JSON.stringify(lastOutstanding));
  }

  get lastOutstanding(): OutstandingLastAddedItem[] {
    return this.getStateProperty('lastOutstanding') as OutstandingLastAddedItem[];
  }

  set lastPendingUser(item: LastAddedItem) {
    this.setStateProperty('lastPendingUser', item);
    this.universalStorageService.setItem('_lastPendingUser', JSON.stringify(item));
  }

  get lastPendingUser(): LastAddedItem {
    return this.getStateProperty('lastPendingUser') as LastAddedItem;
  }

  set lastApaExpense(lastExpense: LastAddedItem[]) {
    this.setStateProperty('lastApaExpense', lastExpense);
  }

  set invitation(invitation: InvitationToBoatDto | null) {
    this.setStateProperty('invitationsToBoat', invitation);
  }

  get invitation(): InvitationToBoatDto | null {
    return this.getStateProperty('invitationsToBoat') as InvitationToBoatDto || null;
  }

  get invitation$(): Observable<InvitationToBoatDto | null> {
    return this.state$.pipe(
      map(state => state.invitationsToBoat || null),
    );
  }

  get forcedLogout(): boolean | null {
    return this.transferState.get<boolean | null>(this.forcedLogoutStateKey, false);
  }

  set forcedLogout(val: boolean | null) {
    if (val === null) {
      this.transferState.remove(this.forcedLogoutStateKey);
    }
    this.transferState.set<boolean | null>(this.forcedLogoutStateKey, val);
  }

  get userCompany$(): Observable<CompanyDto | null> {
    return this.state$.pipe(
      map(state => state.userCompany),
    );
  }

  get userCompany(): CompanyDto | null {
    return this.getStateProperty('userCompany') as CompanyDto | null;
  }

  get userDateUpdate(): number | null {
    const user = this.user;
    return user ? user.lastTimeUpdated : null;
  }

  get isCrewAgency(): boolean {
    const company = this.userCompany;
    return isCompanyCrewAgency(company);
  }

  get userDownloadDateUpdate(): number | null {
    return this.getStateProperty('userDownloadsDateUpdate') as number | null;
  }

  set userDownloadDateUpdate(val: number | null) {
    this.setStateProperty('userDownloadsDateUpdate', val);
  }

  get userFleetsDateUpdate(): number | null {
    return this.getStateProperty('userFleetsDateUpdate') as number | null;
  }

  set userFleetsDateUpdate(val: number | null) {
    this.setStateProperty('userFleetsDateUpdate', val);
  }

  get userFleetInfo$(): Observable<UserFleetData | null> {
    return this.state$.pipe(
      map(state => state?.fleet),
      shareReplay(1),
    );
  }

  get fleet(): UserFleetData | null {
    return this.getStateProperty('fleet') as UserFleetData | null;
  }

  setUserState(user: User) {
    this.setStateProperty('user', user);
  }

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly browserService: PlatformBrowserService,
    @Inject(UniversalStorageService) private readonly universalStorageService: UniversalStorageService,
    private readonly transferState: TransferState,
  ) {
    this.initState();
  }

  private initState(): void {
    const state = this.getStateFromLocalStorage() || new AppState();
    this.state = new BehaviorSubject<AppState>(state);
    this.userInfoLoaded$ = new BehaviorSubject(false);
    this.userLoaded$ = this.userInfoLoaded$.asObservable().pipe(
      filter(val => val),
      take(1),
    );
    this.state$ = this.state.asObservable();
    this.setStateInLocalStorage();
  }

  private getStateFromLocalStorage(): AppState | null {
    return this.localStorageService.getItem<AppState>(this.localStorageKey) || null;
  }

  private setStateInLocalStorage(): void {
    if (this.state) {
      this.state.subscribe(
        (state) => {
          this.localStorageService.setItem<AppState>(this.localStorageKey, state);
        },
      );
    }
  }

  protected getState(): AppState {
    return {...this.state.value};
  }

  protected setState(state: AppState): void {
    this.state.next(state);
  }

  private setStateProperty(key: keyof AppState, value: AppState[keyof AppState]): void {
    const state = this.getState();
    (state as any)[key] = value as any;
    this.setState(state);
  }

  private getStateProperty(key: keyof AppState): AppState[keyof AppState] {
    const state = this.getState();
    return state[key] || null;
  }

  setAuth(data: AuthDto): void {
    this.transferState.set<boolean>(this.unAuthorizedStateKey, false);
    const {
      token,
      boatSearch,
      user,
      cv,
      company,
      isConnectedToBoats,
      releaseInformed,
      location,
      referralLinkToken,
      fleet,
    } = data;
    this.universalStorageToken = token;
    this.boatSearch = boatSearch;
    this.releaseInformed = releaseInformed;
    this.needChangePassword = user.needChangePassword;
    this.universalStorageUserRole = user.roleType;
    this.setStateProperty('token', token);
    this.setStateProperty('user', user);
    this.setStateProperty('cv', cv);
    this.setStateProperty('userCompany', company);
    this.setStateProperty('location', location);
    this.setStateProperty('referralLinkToken', referralLinkToken);
    this.setStateProperty('fleet', fleet);
    this.isConnectedToBoats = isConnectedToBoats;
    this.userInfoLoaded$.next(true);
  }

  clearUserData(): void {
    this.transferState.set<boolean>(this.unAuthorizedStateKey, true);
    this.universalStorageToken = null;
    this.boatSearch = false;
    this.releaseInformed = false;
    this.needChangePassword = false;
    this.universalStorageUserRole = null;
    this.setStateProperty('token', null);
    this.setStateProperty('user', null);
    this.setStateProperty('permissions', null);
    this.setStateProperty('cv', null);
    this.setStateProperty('userCompany', null);
    this.setStateProperty('lastExpense', null);
    this.setStateProperty('lastOutstanding', null);
    this.setStateProperty('lastPendingUser', null);
    this.setStateProperty('location', null);
    this.setStateProperty('isConnectedToBoats', ConditionalStatus.NO);
    this.setStateProperty('needChangePassword', ConditionalStatus.NO);
    this.setStateProperty('referralLinkToken', null);
    this.setStateProperty('fleet', null);
    this.setStateProperty('preferenceList', null);
    this.setStateProperty('lastApaExpense', null);
    this.setStateProperty('fleetPermissions', null);
    this.setStateProperty('boatSearch', null);
    this.setStateProperty('userDownloadsDateUpdate', null);
    this.setStateProperty('userFleetsDateUpdate', null);
    this.setStateProperty('isConnectedToBoats', null);
    this.setStateProperty('userDownloadsDateUpdate', null);
  }

  destroyState(): void {
    this.state.complete();
    this.userInfoLoaded$.complete();
  }

}
