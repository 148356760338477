import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';

const isNodeJs = () => {
  try {
    return new Function('try {return this===global;}catch(e){return false;}')();
  } catch (e) {
    return false;
  }
};

@Injectable()
export class PlatformBrowserService {

  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  get isServer(): boolean {
    return isPlatformServer(this.platformId) && isNodeJs();
  }

  constructor(@Inject(PLATFORM_ID) private readonly platformId: object) {
  }
}
